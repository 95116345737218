import { useState, useContext, useEffect } from "react";
import BackButton from "../../components/Widgets/BackButton";
import SaveChangesMessageRow from "../../components/Widgets/SaveChangesMessageRow";
import ComboBox from "../../components/Widgets/ComboBox";
import validateABN from "../../validators/validateABN";
import AdminContext from "../../AdminContext";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import { set } from "date-fns";
import LoadingSpinner from "components/Widgets/LoadingSpinner";

const SupplierInformation = () => {
  const [isLoading, setIsLoading] = useState(false);
  // Cookies are required for authentication
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [supplierInfo, setSupplierInfo] = useState({});

  const adminContext = useContext(AdminContext);

  useEffect(() => {
    const init = () => {
      setIsLoading(true);
      adminContext.getRequest(
        adminContext.constants.BASE_URL + `/settings/supplier-info`,
        (response) => {
          if (response.data.supplier_info) {
            setSupplierInfo(response.data.supplier_info);
          }
          setIsLoading(false);
        }
      );
    };
    init();
  }, []);

  // Update the form on data change
  const handleChange = (event) => {
    setSupplierInfo({
      ...supplierInfo,
      [event.target.name]: event.target.value,
    });
    let ABNValid = validateABN(supplierInfo.abn);

    if (!ABNValid) {
      showError("Invalid ABN");
    }
  };

  // Update the API on submit
  const saveChanges = (e) => {
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/settings/supplier-info`,
      supplierInfo,
      (response) => {
        if (response.status === 200) {
          adminContext.setAlertMessage("Changes saved successfully");
        }
      }
    );
  };

  const showError = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  if (isLoading || !supplierInfo) {
    return <LoadingSpinner />;
  }

  return (
    <fieldset disabled={!adminContext.editAccess("settings")}>
      <Container className="mt-4" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <h1 className="days-one text-center">Supplier Information</h1>
            {!supplierInfo.id && <BackButton />}

            <SaveChangesMessageRow
              errorMessage={errorMessage}
              successMessage={successMessage}
              saveChanges={saveChanges}
            />
            <Card className="bg-secondary shadow">
              <CardBody>
                <Form key={supplierInfo.id}>
                  <h3 className="text-muted mb-4">
                    Supplier Information (for Agents or Business Intermediaries)
                  </h3>
                  <p>
                    Below is where you can enter supplier information for all
                    companies within this database. This is used in the Single
                    Touch Payroll section if you are submitting on the behalf of
                    an employer.
                  </p>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={supplierInfo.name}
                            id="input-name"
                            placeholder="Enter name here"
                            type="text"
                            name="name"
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-abn"
                          >
                            ABN
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-abn"
                            placeholder="Enter abn here"
                            value={supplierInfo.abn}
                            name="abn"
                            onChange={handleChange}
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-tax_agent_number"
                          >
                            Tax Agent Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-tax_agent_number"
                            placeholder="Enter registered agent number if required"
                            value={supplierInfo.tax_agent_number}
                            name="tax_agent_number"
                            onChange={handleChange}
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="Enter email here"
                            value={supplierInfo.email}
                            name="email"
                            onChange={handleChange}
                            type="email"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-phone"
                            placeholder="Enter phone here"
                            value={supplierInfo.phone}
                            name="phone"
                            onChange={handleChange}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Address information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address1"
                          >
                            Address line 1
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={supplierInfo.address1}
                            onChange={handleChange}
                            name="address1"
                            id="input-address1"
                            placeholder="Address line 1"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address2"
                          >
                            Address line 2
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={supplierInfo.address2}
                            id="input-address2"
                            name="address2"
                            onChange={handleChange}
                            placeholder="Address line 2"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            City
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-city"
                            placeholder="Enter city here"
                            value={supplierInfo.city}
                            name="city"
                            onChange={handleChange}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <div>
                          <ComboBox
                            disabled={!adminContext.editAccess("settings")}
                            label="State"
                            stretch={true}
                            className="mx-3"
                            name="state"
                            onChange={handleChange}
                            comboData={adminContext.constants.STATES}
                            selectedComboItem={supplierInfo.state}
                            setSelectedComboItem={(data) =>
                              setSupplierInfo({ ...supplierInfo, state: data })
                            }
                          />
                        </div>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-postcode"
                          >
                            Postcode
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-postcode"
                            placeholder="Enter postcode here"
                            value={supplierInfo.postcode}
                            name="postcode"
                            onChange={handleChange}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </fieldset>
  );
};
export default SupplierInformation;
