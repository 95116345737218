import { useState, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";
import { Container, Card, CardBody, Row, Col, Alert, Button } from "reactstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBox from "@mui/material/Checkbox";

const OtherSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const adminContext = useContext(AdminContext);

  const saveChanges = (key, val) => {
    setIsLoading(true);

    // This variable will be true when the component is still mounted.
    let isMounted = true;

    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/tools/app-config-item`,
      { key: key, value: val },
      (response) => {
        // Only update state if the component is still mounted.
        if (isMounted) {
          setIsLoading(false);
          setSuccessMessage("Changes saved successfully.");
          setTimeout(() => {
            // Also, only update state if the component is still mounted.
            if (isMounted) {
              setSuccessMessage("");
            }
          }, 3000);
        }
      }
    );

    // Cleanup function that will be run when the component unmounts.
    return () => {
      isMounted = false;
    };
  };

  const markPayslipsSent = () => {
    setIsLoading(true);

    // This variable will be true when the component is still mounted.
    let isMounted = true;

    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/settings/mark-payslips-sent`,
      {},
      (response) => {
        // Only update state if the component is still mounted.
        if (isMounted) {
          setIsLoading(false);
          setSuccessMessage("Payslips marked as sent successfully.");
          setTimeout(() => {
            // Also, only update state if the component is still mounted.
            if (isMounted) {
              setSuccessMessage("");
            }
          }, 3000);
        }
      }
    );

    // Cleanup function that will be run when the component unmounts.
    return () => {
      isMounted = false;
    };
  };

  return (
    <fieldset disabled={!adminContext.editAccess("settings")}>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            {isLoading || !adminContext.appConfig ? (
              <LoadingSpinner />
            ) : (
              <>
                <Row className="mt-2">
                  <Col>
                    <h1 className="days-one">Other (Miscellaneous) Settings</h1>
                  </Col>
                  <Col>
                    {successMessage && (
                      <Alert className="my-2 text-center" color="success">
                        <strong>{successMessage}</strong>
                      </Alert>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      label="Automatically upload data to the web app if changes are detected?"
                      control={
                        <CheckBox
                          checked={
                            adminContext.appConfig.web_app_auto_sync_upload_on
                              .value
                          }
                          color="primary"
                          id="mastercheck"
                          onChange={() => {
                            saveChanges(
                              "web_app_auto_sync_upload_on",
                              !adminContext.appConfig
                                .web_app_auto_sync_upload_on.value
                            );
                          }}
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      label="Prompt me to download data from the web app if changes are detected?"
                      control={
                        <CheckBox
                          checked={
                            adminContext.appConfig.web_app_auto_sync_download_on
                              .value
                          }
                          color="primary"
                          id="mastercheck"
                          onChange={() => {
                            saveChanges(
                              "web_app_auto_sync_download_on",
                              !adminContext.appConfig
                                .web_app_auto_sync_download_on.value
                            );
                          }}
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      label="Manually control web sync uploads/downloads?"
                      control={
                        <CheckBox
                          checked={
                            adminContext.appConfig.web_app_manual_mode.value
                          }
                          color="primary"
                          id="mastercheck"
                          onChange={() => {
                            saveChanges(
                              "web_app_manual_mode",
                              !adminContext.appConfig.web_app_manual_mode.value
                            );
                          }}
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="my-1">
                    <Row>
                      <Col>
                        <div key={adminContext.currentEmployee.id}>
                          <Button
                            name="mark_payslips_sent"
                            color="success"
                            onClick={markPayslipsSent}
                          >
                            Mark All Payslips As Sent To The Online Portal?
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </fieldset>
  );
};
export default OtherSettings;
